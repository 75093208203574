body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(244, 244, 248);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: 'Roboto';
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
}

a {
  font-family: 'Roboto';
  color: rgba(0, 0, 0, 0.87);
}

a:hover {
  color: rgba(0, 0, 0, 0.67);
  cursor: pointer;
}

input {
  width: 300px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  border: 1px solid #dddfe2; 
  margin-top: 24px;
  padding: 10px;
  outline: none;
}

input:focus {
  width: 300px;
}

.input-warning {
  border: 2px solid #f26363;
  height: 48px;
  width: 300px;
}

.input-warning:focus {
  border: 2px solid #f26363;
}

select {
  height: 50px;
  width: 150px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  border: 1px solid #dddfe2;
  padding: 10px;
  outline: none;
  background-color: #ffffff;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dddfe2;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dddfe2;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dddfe2;
}

/* .greenBold {
  font-weight: bold;
  color: #46a667;
}

.redBold {
  font-weight: bold;
  color: #f26363;
} */

.success {
  color: #fff;
  height: 50px;
  width: 300px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 22px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-top: 24px;
  outline: none;
  border-style: none;
}

.success:hover {
  cursor: pointer;
}

.warning {
  color: #fff;
  height: 50px;
  width: 300px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 22px;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  margin-top: 24px;
  outline: none;
  border-style: none;
  background: #f26363;
}

.warning:hover {
  background: #ff9591;
  cursor: pointer;
}

.header {
  font-weight: lighter;
  font-size: 70px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.secondHeader {
  font-size: 30px;
}

.boxHeader {
  font-weight: lighter;
  font-size: 35px;
}

.hint {
  font-weight: lighter;
  font-style: italic;
  color: #a9a9a9;
}

.blurred {
  -webkit-filter: blur(7px);
  -moz-filter: blur(7px);
  -o-filter: blur(7px);
  -ms-filter: blur(7px);
  filter: blur(7px);
}

.mandatoryStar {
  color: #f26363;
}

.patientItem {
  padding: 12px;
  padding-left: 24px;
}

.patientItem:hover {
  background-color: rgb(70, 166, 103);
  cursor: pointer;
  color: #ffffff;
}

.selectedPatientItem {
  padding: 12px;
  padding-left: 24px;
  color: #ffffff;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedPatientItem:hover {
  cursor: pointer;
  color: #ffffff;
  font-weight: bold;
}

.patientSubItem {
  height: 64px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  padding-left: 24px;
}

.patientSubItem:hover {
  cursor: pointer;
  background-color: #f4f4f8;
}

.selectedPatientSubItem {
  height: 64px;
  width: 100%;
  background-color: #f4f4f8;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  align-items: center;
  justify-content: flex-start;
}

.selectedPatientSubItem:hover {
  cursor: pointer;
}

.patientStatusSuccess {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: rgb(70, 166, 103);
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.patientStatusWarning {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #ffeb3b;
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.patientStatusDanger {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #f26363;
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.patientStatusUndefined {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: #a9a9a9;
  border: 1px solid rgba(0, 0, 0, 0.87);
}

.hover:hover {
  cursor: pointer;
}

.warningText {
  color: rgb(242, 99, 99);
}

.successRef {
  color: #46a667;
}

.successRef:hover {
  color: #79d895;
}

.warningRef {
  color: #f26363;
}

.warningRef:hover {
  color: #ff9591;
}

.smallText {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  margin-left: 24px;
}
